import React, {useContext} from "react";
import {Box, CardContent, Grid, ListItem, ListItemText, Typography, useMediaQuery} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles"
import RectangleSharpIcon from "@mui/icons-material/RectangleSharp"
import AuthenticatedLayout from "../components/layout/AuthenticatedLayout";
import {TenantContext} from "../components/provider/TenantProvider";
import ChartGrid from "../components/grid/ChartGrid";
import {useTheme} from "@mui/material/styles";
import {permitsSuper} from "../misc/authorization";
import {LoginContext} from "../components/provider/LoginProvider";
import ResourcesAllocatedBarChart from "../components/chart/ResourcesAllocatedBarChart";
import NodesHeatmapChart from "../components/chart/NodesHeatmapChart";
import {FlatCard} from "../components/Misc";
import {mapColor} from "../components/chart/raw/Charts";
import SingleValueChart from "../components/chart/SingleValueChart";

import "@fontsource/montserrat";
import "@fontsource/metropolis";
import "@fontsource/fira-code";
import Dashboard from "../components/view/overviews/dashboard/Dashboard";

import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import createTheme from "../styles/theme";
import {THEMES} from '../styles/theme/constants';

export default function IndexPage() {
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.down("md"))
    const {login} = useContext(LoginContext)
    const {tenant} = useContext(TenantContext)

    return (
        <MuiThemeProvider theme={createTheme(THEMES.DARK)}>
            <Dashboard/>
        </MuiThemeProvider>
    )
    /*return (<AuthenticatedLayout title="Overview" breadcrumbs={[{name: "Overview", link: "/"}]} size="xl">
        <Box component="div" mt={1}>
            <Grid container spacing={2}>
                {permitsSuper(login) ? (<Grid container item spacing={1}>
                    <Grid item xs={12}><Typography component="span"
                                                   color="text.secondary">All tenants</Typography></Grid>
                    <Grid item xs={12}>
                        <ChartGrid id="all-tenants" layout={[
                            {i: "tenants", x: 0, y: 0, w: 3, h: 3, static: false},
                            {i: "users", x: 3, y: 0, w: 3, h: 3, static: false},
                            {i: "apikeys", x: 6, y: 0, w: 3, h: 3, static: false},
                            {i: "deployments", x: 9, y: 0, w: 3, h: 3, static: false},
                            {i: "cpu", x: 0, y: 3, w: 6, h: 9, static: false},
                            {i: "memory", x: 6, y: 3, w: 6, h: 9, static: false},
                            {i: "nodes", x: 0, y: 12, w: 12, h: 4, static: false},
                        ]} layoutSm={[
                            {i: "tenants", x: 0, y: 0, w: 3, h: 3, static: false},
                            {i: "users", x: 3, y: 0, w: 3, h: 3, static: false},
                            {i: "apikeys", x: 0, y: 3, w: 3, h: 3, static: false},
                            {i: "deployments", x: 3, y: 3, w: 3, h: 3, static: false},
                            {i: "cpu", x: 0, y: 38, w: 6, h: 8, static: false},
                            {i: "memory", x: 0, y: 46, w: 6, h: 8, static: false},
                            {i: "nodes", x: 0, y: 46, w: 6, h: 4, static: false},
                        ]} items={[
                            <div key="tenants">
                                <SingleValueChart id="all-tenants-tenants"
                                                  header="Tenants"
                                                  subHeader="last 30 days"
                                                  data={[35, 35, 34, 30, 20, 4, 4, 4, 4, 4, 4, 5, 5, 6, 7, 7, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 30, 30, 30, 30]}
                                                  height={isSm ? "50px" : "50px"}/>
                            </div>,
                            <div key="users">
                                <SingleValueChart id="all-tenants-users"
                                                  header="Users"
                                                  subHeader="last 30 days"
                                                  data={[7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 23, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7]}
                                                  height={isSm ? "50px" : "50px"}/>
                            </div>,
                            <div key="apikeys">
                                <SingleValueChart id="all-tenants-apikeys"
                                                  header="API keys"
                                                  subHeader="last 30 days"
                                                  data={[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3]}
                                                  height={isSm ? "50px" : "50px"}/>
                            </div>,
                            <div key="deployments">
                                <SingleValueChart id="all-tenants-deployments"
                                                  header="Deployments"
                                                  subHeader="last 30 days"
                                                  data={[60, 60, 63, 64, 65, 68, 76, 76, 76, 79, 80, 81, 80, 90, 93, 94, 96, 98, 99, 100, 102, 129, 109, 110, 112, 122, 128, 128, 130, 138]}
                                                  height={isSm ? "50px" : "50px"}/>
                            </div>,
                            <div key="cpu">
                                <ResourcesAllocatedBarChart id="all-tenants-cpu" header="CPU"
                                                            data={[
                                                                {
                                                                    name: "Allocated",
                                                                    value: 60,
                                                                    unit: " cores",
                                                                    color: "primary"
                                                                },
                                                                {
                                                                    name: "Limited",
                                                                    value: 100,
                                                                    unit: " cores",
                                                                    color: "primary"
                                                                },
                                                            ]}
                                                            dataTotal={{name: "Total", value: 128, unit: " cores"}}
                                                            height={isSm ? "200px" : "250px"}/>
                            </div>,
                            <div key="memory">
                                <ResourcesAllocatedBarChart id="all-tenants-memory" header="Memory"
                                                            data={[
                                                                {
                                                                    name: "Allocated",
                                                                    value: 500,
                                                                    unit: " GB",
                                                                    color: "alert"
                                                                },
                                                                {
                                                                    name: "Limited",
                                                                    value: 600,
                                                                    unit: " GB",
                                                                    color: "error"
                                                                },
                                                            ]}
                                                            dataTotal={{name: "Total", value: 512, unit: " GB"}}
                                                            height={isSm ? "200px" : "250px"}/>
                            </div>,
                            <div key="nodes">
                                <NodesHeatmapChart id="all-tenants-nodes" header="Nodes"
                                                   data={Array.from({length: 25}, (v, k) => k).map(it => {
                                                       const status = it === 1 ? Math.floor(Math.random() * 3) + 1 : it === 3 ? Math.floor(Math.random() * 3) + 1 : 1
                                                       return {name: "", value: status as 1 | 2 | 3}
                                                   })}
                                                   height={isSm ? "100px" : "100px"}/>
                            </div>
                        ]}/>
                    </Grid>
                </Grid>) : null}
                {tenant == null ? null : (<Grid item xs={12}>
                    <Grid container item spacing={1}>
                        <Grid item xs={12}><Typography component="span"
                                                       color="text.secondary">Tenant: {tenant.tenant.name}</Typography></Grid>
                        <Grid item xs={12}>
                            <ChartGrid id="tenant" layout={[
                                {i: "deployments", x: 0, y: 0, w: 12, h: 3, static: false},
                                {i: "cpu", x: 0, y: 3, w: 6, h: 9, static: false},
                                {i: "memory", x: 6, y: 12, w: 6, h: 9, static: false},
                            ]} layoutSm={[
                                {i: "deployments", x: 0, y: 0, w: 6, h: 3, static: false},
                                {i: "cpu", x: 0, y: 3, w: 6, h: 8, static: false},
                                {i: "memory", x: 0, y: 11, w: 6, h: 8, static: false},
                            ]} items={[
                                <div key="deployments">
                                    <SingleValueChart id="tenant-deployments"
                                                      header="Deployments"
                                                      subHeader="last 30 days"
                                                      data={[6, 6, 6, 6, 6, 6, 7, 7, 7, 7, 8, 8, 8, 9, 9, 9, 9, 9, 9, 10, 10, 12, 10, 11, 11, 12, 12, 12, 13, 13]}
                                                      height={isSm ? "50px" : "50px"}/>
                                </div>,
                                <div key="cpu">
                                    <ResourcesAllocatedBarChart id="tenant-cpu" header="CPU"
                                                                data={[
                                                                    {
                                                                        name: "Allocated",
                                                                        value: 4.5,
                                                                        unit: " cores",
                                                                        color: "primary"
                                                                    },
                                                                    {
                                                                        name: "Limited",
                                                                        value: 6,
                                                                        unit: " cores",
                                                                        color: "primary"
                                                                    },
                                                                ]}
                                                                dataTotal={{name: "Total", value: 8, unit: " cores"}}
                                                                height={isSm ? "200px" : "250px"}/>
                                </div>,
                                <div key="memory">
                                    <ResourcesAllocatedBarChart id="tenant-memory" header="Memory"
                                                                data={[
                                                                    {
                                                                        name: "Allocated",
                                                                        value: 10,
                                                                        unit: " GB",
                                                                        color: "primary"
                                                                    },
                                                                    {
                                                                        name: "Limited",
                                                                        value: 10,
                                                                        unit: " GB",
                                                                        color: "primary"
                                                                    },
                                                                ]}
                                                                dataTotal={{name: "Total", value: 16, unit: " GB"}}
                                                                height={isSm ? "200px" : "250px"}/>
                                </div>,
                            ]}/>
                        </Grid>
                    </Grid>
                </Grid>)}
            </Grid>
        </Box>
    </AuthenticatedLayout>)*/
}
