import React from 'react';

import {Divider, Grid} from "@mui/material";

import Overview from "./Overview";
import Costs from "./Costs";
import Resources from "./Resources";

import AuthenticatedLayout from "../../../layout/AuthenticatedLayout";
import TagFilter from "../../../filter/TagFilter";

export default function Dashboard() {
    return (
        <AuthenticatedLayout title="Dashboard" breadcrumbs={[]} size="xl">
            <TagFilter/>
            <Grid container spacing={2}>
                <Overview/>
                <Divider sx={{width: '100%', margin: '20px 0'}}/>

                <Costs/>
                <Divider sx={{width: '100%', marginBottom: '10px'}}/>

                <Resources/>
            </Grid>
        </AuthenticatedLayout>
    )
}
